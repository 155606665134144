import React from "react"
import { graphql, withPrefix } from "gatsby"

import Layout from "components/Layout"
import Breadcrumbs from "components/Breadcrumbs"
import ContactForm from "components/ContactForm"

import {
  Hero,
  Nav,
  Apartments,
  Gallery,
  About,
  Location,
  Convenients,
} from "page_components/office"

const TrustInvestmentOffice = ({ data, location }) => {
  const title = "Sky Trust Offices and Premises"
  const allApartments = data.allWpOfficeSkyTrust.nodes

  return (
    <Layout
      location={location}
      seo={{
        title: "Sky Trust biura i lokale - Biznesowy adres numer jeden",
        description:
          "Wysokiej klasy powierzchnie biurowe, w topowej lokalizacji. Przenieś swój biznes pod najbardziej prestiżowy adres w Kielcach.",
        image: withPrefix("/og-image-sky-trust-offices.jpg"),
      }}
      rmGlobalPopup
    >
      <Breadcrumbs
        title={title}
        parent={{ label: "Kielce", url: "/en/offices-and-premises/kielce" }}
        subParent={{
          label: "Offices and Premises",
          url: "/en/offices-and-premises",
        }}
      />
      <Hero title={title} />
      <Nav title={title} />
      <Apartments
        apartmentList={allApartments}
        investment="Sky Trust Offices and Premises"
        investmentRaw="biura_sky_trust"
      />
      <Gallery />
      <About title={title} />
      <Location />
      <Convenients />
      <ContactForm
        formType="sale"
        investment="Sky Trust Offices and Premises"
        investmentRaw="biura_sky_trust"
      />
    </Layout>
  )
}

export const query = graphql`
  query {
    allWpOfficeSkyTrust(sort: { fields: title, order: ASC }) {
      nodes {
        id
        title
        uri
        slug
        acfOffice {
          investment
          type
          apartmentNumber
          buildingNumber
          area
          floor
          location
          price
          priceForSquareMeter
          status
          visualization2d {
            sourceUrl
          }
        }
      }
    }
  }
`

export default TrustInvestmentOffice
